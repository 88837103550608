import React from 'react'

import '~/styles/index.scss'

const Layout = ({ children }) => (
  <>
    <main>{children}</main>
  </>
)

export default Layout
